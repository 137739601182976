import React from 'react'
import Helmet from 'react-helmet'
import { Layout, Heading, Row, Column, Image, Section, Text } from 'components'
import Blog from 'images/blogs/blog-22.png'
import {
  CaltexLogo,
  FCHomeLogo,
  GreentelcomLogo,
  HomeAlongLogo,
  MemoExpressLogo,
  NSECLogo,
  ViewersLogo,
} from 'images/partner_logos'
import DigiPartnerLogo from '../../images/digipartner-logo.png'
import 'components/css/stories.css'
import HostName from '../../data/static/HostNames'

const SITE_META = [
  {
    name: 'description',
    content: 'Mabilis, Madali, Mura',
  },
  {
    name: 'keywords',
    content:
      'digipay, fintech, e-payments, bills payment, ' +
      'mobile money, e-load, e-pins, microinsurance',
  },
  {
    property: 'og:url',
    content: `https://${process.env.GATSBY_REACT_APP_HOSTNAME}/stories/PeraPadala`,
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    property: 'og:title',
    content: 'PERA PADALA? #DIGIPADALANAYAN',
  },
  {
    property: 'og:description',
    content:
      'Gusto mo bang magpadala ng pera pero wala kang account sa bangko? Dito ka na sa DigiPadala. Sa tulong ng aming Digipay Agents, mas madali nang magpadala at tumanggap ng pera saan man sa Pilipinas. Libre ang claim kaya #DigiPadalaNaYan!',
  },
  {
    property: 'og:image',
    content: Blog,
  },
]

const partner_logos = [
  {
    src: CaltexLogo,
  },
  {
    src: FCHomeLogo,
  },
  {
    src: GreentelcomLogo,
  },
  {
    src: HomeAlongLogo,
  },
  {
    src: MemoExpressLogo,
  },
  {
    src: NSECLogo,
  },
  {
    src: ViewersLogo,
  },
]

const PeraPadala = () => (
  <Layout>
    <Helmet title="PERA PADALA? #DIGIPADALANAYAN" meta={SITE_META}>
      <html lang="en" />
    </Helmet>

    <Row>
      <Column size="df:[100%] lg:[col-6]" py="sp4" px="sp3" m="auto">
        <Image width="100%" mb="sp3" src={Blog} />
        <Heading
          className="blog__title"
          mb="sp4"
          fontWeight="bold"
          textAlign="center"
        >
          PERA PADALA? #DIGIPADALANAYAN
        </Heading>
        <Text className="blog__title-tagline">
          Gusto mo bang magpadala ng pera pero wala kang account sa bangko? Dito
          ka na sa DigiPadala. Sa tulong ng aming Digipay Agents, mas madali
          nang magpadala at tumanggap ng pera saan man sa Pilipinas. Libre ang
          claim kaya #DigiPadalaNaYan!
        </Text>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            MABILIS
          </Heading>
          <Text>
            Kung ikaw ay{' '}
            <b>
              <i>magpapadala ng pera</i>
            </b>
            , kailangan mo lang ang mga sumusunod:
          </Text>
          <div className="blog__two-columned-list">
            <ul>
              <li>Isang (1) government issued valid ID</li>
              <li>
                Pangalan ng receiver o tatanggap ng pera (dapat tugma sa valid
                ID ng receiver)
              </li>
              <li>Mobile number ng tatanggap ng pera</li>
            </ul>
          </div>
          <Text>
            Kung ikaw naman ay{' '}
            <b>
              <i>tatanggap ng pera</i>
            </b>
            , kailangan mo lang ibigay ang mga sumusunod:
          </Text>
          <div className="blog__two-columned-list">
            <ul>
              <li>Amount at transaction reference number</li>
              <li>Detalye ng sender o nagpadala ng pera</li>
              <li>Isang (1) government-issued valid ID</li>
            </ul>
          </div>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            MADALI
          </Heading>
          <Text>
            Mayroong over 2,500 DigiPadala outlets sa bansa kaya kung gusto mong
            magpadala o tumanggap ng pera, humanap lang ng nearest branch sa{' '}
            <a
              className="inline-link"
              href="https://www.digipay.ph/branch-locator/"
            >
              {' '}
              <b>
                <i>DigiPadala Branch Locator</i>
              </b>
            </a>
            . Ipakita ang requirements and in just a few minutes, makakatanggap
            ka na ng SMS mula sa Digipay kapag successful ang transaction.
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            MURA
          </Heading>
          <Text>
            Pera padala starts at P15 at tandaan na LIBRE ANG CLAIM, kaya ano
            pang hinihintay niyo?
            <a className="inline-link" href="">
              {' '}
              <b>
                <i> #DigiPadalaNaYan</i>
              </b>
            </a>
          </Text>
        </Section>

        <Image
          width="100%"
          height="20%"
          mt="sp5"
          mb="sp5"
          src={DigiPartnerLogo}
        />

        <Section mb="sp4">
          <Row flexAlign="center" flexJustify="center">
            <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
              ALSO AVAILABLE AT
            </Heading>
          </Row>
          <Row flexAlign="center" flexJustify="center">
            {partner_logos.map((data, index) => (
              <Image
                width="20%"
                height="20%"
                mb="sp3"
                src={data.src}
                key={index}
                py="sp4"
                px="sp3"
              />
            ))}
          </Row>

          <Text px="sp3" textAlign="center">
            ...and more
          </Text>
        </Section>
      </Column>
    </Row>
  </Layout>
)

export default PeraPadala
